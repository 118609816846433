
.header {
    background-color: #1C1E4A;
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: space-between;
    padding: 20px 20px;

    .profile {
      display: none;
    }
  
    .logo {
      width: 100px;
      height: fit-content;
      margin-left: 65px;
      border-radius: 5px;
    }

  @media (min-width: 1200px) {
      .menu {
        display: none;
      }
      
      .profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;

        img{
          width: 40px;
          background-color: #fff;
          height: 40px;
          margin-right: 15px;
          border-radius: 40px;
        }
      }
  
      .logo {
        width: 100px;
        height: fit-content;
        margin-left: 45px;
        border-radius: 5px;
      }
    }
  }
  
.ant-drawer-close {
  color: #fff !important;
}

.ant-drawer-title {
  color: #fff !important;
}
  