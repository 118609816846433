.menu{
  background-color: #1C1E4A;
  padding: 0px;
}

.ant-menu-item {
  color: #fff !important;
  margin: 0px !important;
  border-radius: 0px !important;
  width: 100% !important;
  padding: 22px 15px  !important;;
}

.ant-menu-item-selected{
  color: #1C1E4A !important;
  border-bottom-color: #1C1E4A;
  background-color: #fff !important;
  margin-right: 3px !important;
  width: 100% !important;
  border-right-width: 5px;
  margin-top: -1px !important;
  border-right-color: #1C1E4A;
  border-right-style: solid;
  padding: 22px 15px  !important;
}
